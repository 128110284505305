import Vue from "vue";

Vue.prototype.$rules = {
  required: (value) => !!value || "Este campo es requerido.",
  minLength: (value, minLength) =>
    (value && value.length >= minLength) ||
    `Este campo debe tener al menos ${minLength} caracteres.`,
  maxLength: (value, maxLength) =>
    (value && value.length <= maxLength) ||
    `Este campo debe tener como máximo ${maxLength} caracteres.`,
  email: (value) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
    "Ingresa un correo electrónico válido.",
  numeric: (value) => {
    console.log(value)
    return  /^\d+(.\d+)?$/.test(value) || "Este campo solo acepta valores numéricos."
  },
  minValue: (value, minValue) =>
    (value && Number(value) >= minValue) ||
    `El valor mínimo permitido es ${minValue}.`,
  maxValue: (value, maxValue) =>
    (value && Number(value) <= maxValue) ||
    `El valor máximo permitido es ${maxValue}.`,
  gt: (value, min) => {
    console.log(`value: ${value} - min: ${min}`)
    return value > min || `Dee ingresar un valor mayor a ${min}`
  }
};