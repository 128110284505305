import firebase from 'firebase/app'
import axios from 'axios'
import Vue from 'vue'
const auth = firebase.auth();

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      console.log(originalRequest)
      if (error.response.status == 401 && !originalRequest.url?.includes('emitir-factura')) {
        let newToken = await auth.currentUser.getIdTokenResult(true);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + newToken.token;
        originalRequest.headers["Authorization"] = "Bearer " + newToken.token;
        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );
  if (process.env.NODE_ENV === "development") {
    // axios.defaults.baseURL = "http://localhost:8111/";
    axios.defaults.baseURL = "https://quenco-dev-api-ckeyhpxk7q-uc.a.run.app/";
  }
  if (process.env.NODE_ENV === "qa") {
    axios.defaults.baseURL = "https://quenco-testing.umatec.pe/";
  }
  if (process.env.NODE_ENV === "production") {    
    axios.defaults.baseURL = "https://quenco-api-ckeyhpxk7q-uc.a.run.app/";
  }
Vue.prototype.$http = axios
