import Vue from "vue";

Vue.prototype.$enums = {
  ACTIONS: {
    CREATE: "create",
    EDIT: "edit",
    SHOW: "show",
    SHOW_NOTES: "showNotes",
  },
  PRODUCTS_ORDER: {
    ALPHABETIC: "alpha",
    CATEGORY: "category",
    NEWEST: "newest",
    FIRST_DISCOUNT: "first_disc",
    LOWEST_PRICE: "less_price",
    HIGHEST_PRICE: "highest_price",
  },
  DOC_TYPES: [
    { value: "dni", text: "DNI" },
    { value: "ruc", text: "RUC" },
    { value: "ce", text: "CE" },
  ],
  STATUSES: [
    { value: "por_revisar", text: "POR REVISAR" },
    { value: "por_coordinar", text: "POR COORDINAR" },
    { value: "aprobado", text: "APROBADO" },
    { value: "descartado", text: "DESCARTADO" },
  ],
  ORIGINS: [
    { value: "web", text: "WEB" },
    { value: "manual", text: "MANUAL" },
  ],
  ROUTE_STATUSES: [
    { value: "available", text: "EN ESPERA" },
    { value: "ready", text: "LISTO PARA PARTIR" },
    { value: "on_route", text: "EN RUTA" },
    { value: "delivered", text: "ENTREGADO" },
    { value: "reviewed", text: "REVISADO" },
    { value: "completed", text: "COMPLETADO" },
  ],
  UNITS: [
    { text: "Kilogramo (kg)", value: "kg" },
    { text: "Unidades (ud)", value: "ud" },
    { text: "Toneladas (t)", value: "t" },
    { text: "Docenas", value: "docena" },
    { text: "Jabas", value: "jaba" },
    { text: "Cajas", value: "caja" },
    { text: "Paquetes", value: "paquete" },
    { value: "BARRILES", text: "Barriles" },
    { value: "BOLSA", text: "Bolsa" },
    { value: "BOTELLAS", text: "Botellas" },
    { value: "CARTONES", text: "Cartones" },
    { value: "CIENTO_DE_UNIDADES", text: "Ciento de Unidades" },
    { value: "DOCENA", text: "Docena" },
    { value: "FARDO", text: "Fardo" },
    { value: "GRAMO", text: "Gramo" },
    { value: "GRUESA", text: "Gruesa" },
    { value: "JUEGO", text: "Juego" },
    { value: "KIT", text: "Kit" },
    { value: "LATAS", text: "Latas" },
    { value: "LIBRAS", text: "Libras" },
    { value: "LITRO", text: "Litro" },
    { value: "MILLARES", text: "Millares" },
    { value: "MILLON_DE_UNIDADES", text: "Millón de Unidades" },
    { value: "ONZAS", text: "Onzas" },
    { value: "PALETAS", text: "Paletas" },
    { value: "PAR", text: "Par" },
    { value: "PIEZAS", text: "Piezas" },
    { value: "PLIEGO", text: "Pliego" },
    { value: "TONELADA_CORTA", text: "Tonelada Corta" },
    { value: "TONELADA_LARGA", text: "Tonelada Larga" },
    { value: "TONELADAS", text: "Toneladas" },
  ],
  PERMISSIONS: [
    "pedidos",
    "categorias",
    "productos",
    "clientes",
    "proveedores",
    "transportistas",
    "usuarios",
    "configuracion",
    "corporativo",
    "cupones",
    "estadisticas",
  ],
  MOVEMENT_TYPES: [
    {
      text: "ENTRADA",
      value: "INCOMING",
    },
    {
      text: "SALIDA",
      value: "OUTGOING",
    },
  ],
  MOVEMENT_TYPES_PARSER(value) {
    switch (value) {
      case "INCOMING":
        return "ENTRADA";
      case "OUTGOING":
        return "SALIDA";
    }
    return value;
  },
  INCOMING_REASONS: [
    {
      text: "COMPRA",
      value: "BUY",
    },
    {
      text: "REINGRESO",
      value: "REINGRESS",
    },
    {
      text: "SUBPRODUCTO",
      value: "SUBPRODUCT",
    },
  ],
  INCOMING_REASONS_PARSER(value) {
    switch (value) {
      case "BUY":
        return "COMPRA";
      case "REINGRESS":
        return "REINGRESO";
      case "SUBPRODUCT":
        return "SUBPRODUCTO";
    }
    return value;
  },
  OUTGOING_REASONS: [
    {
      text: "VENTA",
      value: "SELL",
    },
    {
      text: "MERMA",
      value: "DECREASE",
    },
    {
      text: "REINGRESO",
      value: "REINGRESS",
    },
    {
      text: "SUBPRODUCTO",
      value: "SUBPRODUCT",
    },
  ],
  OUTGOING_REASONS_PARSER(value) {
    switch (value) {
      case "SELL":
        return "VENTA";
      case "DECREASE":
        return "MERMA";
      case "REINGRESS":
        return "REINGRESO";
      case "SUBPRODUCT":
        return "SUBPRODUCTO";
    }
    return value;
  },
  AUTH_TYPE_COMBO: [
    { text: "USUARIO", value: "username" },
    { text: "EMAIL", value: "email" },
  ],
  AUTH_TYPE_PARSER(value) {
    switch (value) {
      case "username":
        return "USUARIO";
      case "email":
        return "EMAIL";
    }
    return value;
  },
  USER_ROLES: [
    { text: "Administrador", value: "admin" },
    { text: "Gestionar Pedidos", value: "order-manager" },
  ],
  USER_ROLES_PARSER(value) {
    switch (value) {
      case "admin":
        return "Administrador";
      case "order-manager":
        return "Gestionar Pedidos";
    }
    return value;
  },
  INVENTORY_TYPES:{
    RAW_MATERIAL : "raw-material",
    FINAL_PRODUCT: "final-product"
  },
  INVENTORY_TYPES_COMBO: [
    {text:'Materia Prima', value: 'raw-material'},
    {text:'Producto Final', value: 'final-product'},
  ],
  INVENTORY_TYPES_PARSER(value){
    console.log(value)
    switch(value){
      case 'raw-material': return 'Materia Prima'
      case 'final-product': return 'Producto Final'
    }
    return ''
  }
};
